import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import BlogCard from '../../components/BlogCard'

const BlogPage = props => {
  const {
    data: {
      allMarkdownRemark: { edges: blogs },
    },
  } = props
  return (
    <Layout>
      <SEO title="Site Sent - Blog" />
      <div className="flex flex-wrap justify-center max-w-screen-lg mx-auto">
        {blogs.map(data => {
          const {
            node: {
              id,
              fields: { slug },
              frontmatter: { title, description, featuredimage },
            },
          } = data
          return (
            <Link key={id} to={slug} className="my-2 inline-block h-card">
              <BlogCard
                header={title}
                description={description}
                image={featuredimage}
              />
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

BlogPage.defaultProps = {}

BlogPage.propTypes = {
  // eslint-disable-next-line prettier/prettier
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  // data: PropTypes.shape({
  //   edges: PropTypes.shape({
  //     node: PropTypes.arrayOf({
  //       fields: PropTypes.shape({
  //         slug: PropTypes.string.isRequired,
  //       }),
  //     }),
  //   }),
  // }).isRequired,
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___date }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
