import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => {
  return (
    <div className="pb-16">
      <header className="fixed top-0 left-0 right-0 z-10">
        <div className="h-16 bg-gray-900 flex justify-center items-center">
          <Link to="/blog" className="text-white">
            <h2 className="m-0 text-4xl font-bold">{siteTitle}</h2>
          </Link>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
