import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const config = {
  maxDescriptionLength: 100,
}

const Card = ({ header, description, image }) => {
  const truncatedDescription =
    description.length > config.maxDescriptionLength
      ? `${description.substring(0, config.maxDescriptionLength)}...`
      : description

  return (
    <div className="max-w-xs mt-5 w-64 h-full">
      <div className="bg-white rounded-lg my-5 mx-2 h-full shadow-lg overflow-hidden">
        <Img
          className="h-64 object-contain object-top"
          objectFit="shrink"
          fluid={image.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          imgClass="object-top"
        />
        <div className="p-4 text-center">
          <h4 className="mb-1 font-semibold text-lg">{header}</h4>
          <p className="text-gray-700 leading-tight">{truncatedDescription}</p>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

Card.defaultProps = {
  header: ``,
  description: ``,
  image: ``,
}

export default Card
